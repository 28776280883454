
.interlocksettings-input-number {
    width: 50px;
    margin-left: 5px;
    margin-right: 5px;
}

.interlocksettings-input-checkbox {
    width: 22px;
    height: 22px;
    margin-left: -26px;
}

.interlocksettings-setting {
    margin-left: 23px;
    margin-top: 5px;
    margin-bottom: 5px;
}

.sync-now-btn {
    width: 70px;
    font-size: 10px;
    padding: 0;
    line-height: 10px;
    height: 30px;
    margin: 0px;
    margin-left: 10px;
}

.sync-now-loader {
    border: 5px solid rgb(245,185,66);
    border-top: 5px solid transparent;
    margin-left: 5px;
    border-radius: 50%;
    width: 15px;
    height: 15px;
    display: inline-block;
    vertical-align: middle;
    animation: spin .5s linear infinite;
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}

.sync-now-error {
    width: 30px;
    height: 30px;
    line-height: 30px;
    background: #990000;
    border-radius: 50%;
    color: white;
    font-size: 20px;
    font-weight: bold;
    font-family: verdana;
    text-align: center;
    display: inline-block;
    vertical-align: middle;
    margin-left: 5px;
}
.sync-now-error::after {
    content: 'X';
}