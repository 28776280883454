
.input-recent-activity-date {
    margin-left: 5px;
    margin-right: 5px;
}

.form-set-equipment-stats label {
    vertical-align: middle;
}

.form-set-equipment-stats-label-date {
    color: #ECECEC;
    margin-left: 10px;
}

.form-set-equipment-stats-label-date input {
    margin-left: 10px;
}
