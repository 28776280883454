.settings-list-item {
    background-color: rgb(23,24,28);
    color: #fff;
    font-size: 25px;
    display: block;
    padding: 10px;
    padding-left: 20px;
    border-radius: 5px;
    max-width: 400px;
    border-color: #000;
    border-style: solid;
}

.settings-list-item:hover {
    background-color: rgb(52,53,56);
}

.settings-list-item .chevron {
    float: right;
    margin-right: 10px;
}

.chevron::before {
	border-style: solid;
	border-width: 0.25em 0.25em 0 0;
	content: '';
	display: inline-block;
	height: 0.45em;
	left: 0.15em;
	position: relative;
	top: 0.5em;
	transform: rotate(-45deg);
	vertical-align: top;
    width: 0.45em;
}

.chevron.right:before {
	left: 0;
	transform: rotate(45deg);
}