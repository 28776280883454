.useredit-select {
    margin-top: 10px;
}

.useredit-select select {
    padding-top: 0;
    padding-bottom: 0;
    line-height: 38px;
    font-size: 15px;
}

.useredit-select-container {
    margin-top: 10px;
}

.ag-list-item {
    margin-top: 5px;
    margin-bottom: 5px;
}

.ag-list-item a {
    max-width: 260px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: inline-block;
    white-space: nowrap;
}

.ag-list-item .ag-btn-small {
    float: right;
}