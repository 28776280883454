.userlist-invite-btn {
    width: 80px;
    font-size: 15px;
    padding: 0;
    line-height: 15px;
    height: 39px;
    margin: 0px;
    border: none;
}

.userlist-invite-input {
    margin-right: 10px;
}
.userlist-invite-input-email {
    margin-right: 10px;
    width: 200px;
}

.userlist-invite-select {
    display: inline-block;
    margin: 0;
    margin-right: 10px;
}
.userlist-invite-select select {
    padding-top: 0;
    padding-bottom: 0;
    line-height: 38px;
    font-size: 15px;
}