code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

span {
  letter-spacing: 0;
  color: #DBF1FF;
  opacity: 1;
  font-size: 17px;
}

a {
  text-decoration: none;
  letter-spacing: 0;
  color: #DBF1FF;
  opacity: 1;
  font-size: 17px;
}

button.primary, .btn.primary {
  background: #FFB70C 0% 0% no-repeat padding-box;
  border: 1px solid #43425D;
  opacity: 1;
  color: #141414;
}

button, .btn {
  width: 222px;
  height: 60px;
  border: 1px solid #DBF1FF;
  border-radius: 4px;
  opacity: 1;
  text-align: center;
  letter-spacing: 0;
  background: #282828;
  color: #DBF1FF;
  opacity: 1;
  font-size: 21px;
  margin: 20px;
  line-height: 60px;
  display: inline-block;
}

input {
  background-color: #282828;
  border: none;
  padding: 10px;
  border-bottom-style: solid;
  text-align: left;
  font-size: 15px;
  letter-spacing: 0;
  color: #DBF1FF;
  opacity: 1;
}

input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus,
input:-internal-autofill-selected {
  filter: invert(100%) sepia(100%) saturate(0%) brightness(220%) contrast(100%)
}

input[type=text]:focus,
input[type=search]:focus,
input[type=number]:focus {
  outline: none ;
}

.error-text {
  color: rgb(204, 0, 0);
  white-space: pre-wrap;
}

input[type=checkbox].fab {
  display:none
}

a.fab,button.fab,input[type=checkbox].fab+label{
  background-image: url('./icons/icons8-add-96.png');
  background-repeat: no-repeat;
  background-position: 0 0;
  background-color: #141414;
  border-radius: 100%;
  width:96px;
  height:96px;
  border:none;
  outline:none;
  box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
  transition:.3s;  
  -webkit-tap-highlight-color: rgba(0,0,0,0);
  right: 0;
  bottom: 0;
  position: fixed;
  margin: 30px;
  z-index: 1;
}
a.fab:active,button.fab:active,input[type=checkbox].fab+label:active {
  background-color: #43425D !important;
}
a.fab:hover,button.fab:hover,input[type=checkbox].fab+label:hover,input[type=checkbox].fab:checked+label {
  background-color: #282828;
}
.input-field-label {
  color: #DBF1FF;
  display: block;
}
.input-field-label input[type=text] {
  margin-top: 10px;
  display: block;
}
.input-field-container {
  padding: 10px;
}

.menu-item {
    background-color: rgb(23, 24, 28);
    width: 100%;
    height: 40px;
    font-size: 16px;
    border-spacing: 0px;
    border: none;
    margin: 0px;
    line-height: 40px;
    display: inline-block;
    text-align: left;
    padding-left: 20px;
    padding-right: 20px;
    box-sizing: border-box;
    white-space: nowrap;
    cursor: default;
}

.menu-item:hover {
    background-color: rgb(55, 55, 55);
}

.input-search-list-container {
  position: relative;
}

.input-search-list {
  width: 100%;
  list-style-type: none;
  padding-left: 0px;
  position: absolute;
  background: rgb(32, 33, 36);
  z-index: 1;
  box-shadow: 0px 3px 6px #000000;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  overflow: hidden;
  margin: 0;
}

.input-search-listitem {
  width: 100%;
  color: #ECECEC;
  background: rgb(32, 33, 36);
  padding: 10px;
}

.input-search-listitem:hover {
  background: rgb(52, 53, 56);
  cursor: default;
}

.header-error {
  font-size: 19px;
  letter-spacing: 0;
  color: rgb(255, 0, 0);
  opacity: 0.5;
  height: 30px;
  margin: 10px;
}

.header-success {
  font-size: 19px;
  letter-spacing: 0;
  color: rgb(0, 255, 0);
  opacity: 0.5;
  height: 30px;
  margin: 10px;
}

input[type=checkbox]:focus {  
  outline: auto;
}