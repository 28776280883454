.interlock-indicator {
    border-radius: 10px;
    width: 10px;
    height: 10px;
    font-size: 10px;
    line-height: 15px;
    position: absolute;
    top: 7px;
    left: 7px;
    margin: 0;
    padding: 0;
    text-align: center;
    border-color: rgb(32, 32, 32);
    border-style: solid;
    border-width: 1px;
    font-weight: bold;
    text-transform: uppercase;
    color: rgb(196,196,196);
}

.interlock-indicator.online {
    background-color: rgb(0,196,0);
}

.interlock-indicator.offline {
    background-color: rgb(96,96,96);
}
