.Login-container {
    display: flex
}

.Login-rfid-photo-container {
    width: 960px;
    overflow: hidden;
}
.Login-rfid-photo {
    position: relative;
    top: -36px;
    left: -500px;
    opacity: 1;
}

.Login-logo {
    margin-top: 235px;
    width: 373px;
    height: 100px;
    opacity: 1;
}
  
.Login-form-container {
    float: right;
    top: -36px;
    left: 960px;
    width: 960px;
    background: #282828 0% 0% no-repeat padding-box;
    opacity: 1;
    text-align: center;
}

.Login-form input {
    margin: 20px;
}

.Login-header {
    font-size: 21px;
    letter-spacing: 0;
    color: #DBF1FF;
    opacity: 0.5;
    margin-bottom: 50px;
}

.Login-header-error {
    font-size: 19px;
    letter-spacing: 0;
    color: rgb(255, 0, 0);
    opacity: 0.5;
    margin-bottom: 50px;
}

.Login-input {
    width: 400px;
}

.Login-input-sm {
    width: 200px;
    margin-right: 20px;
}

.Login-form-inputs {
    text-align: left;
    margin-left: 250px;
}

.Login-input-checkbox {
    top: 594px;
    left: 1200px;
    width: 22px;
    height: 22px;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 1px solid #808495;
    border-radius: 4px;
    opacity: 1;
}

.Login-forgot-password {
    margin-left: 250px;
}

.Login-signin-link {
    text-align: center;
    text-decoration: underline;
    letter-spacing: 0;
    color: #FFB70C;
}