html,body,#root {
    height: 100%;
    margin: 0;
    padding: 0;
    background-color: #000000;
}
.scaffold {
    height: 100%;
}

.scaffold-logo {
    width: 84px;
    height: 84px;
    background: #141414 0% 0% no-repeat padding-box;
    background-position:center center;
    background-image: url('../../icons/rfidinterlock-logo-small.png');
}

.scaffold-scaffold-top {
    position: fixed;
    top: 0px;
    z-index: 9999;
    height: 84px;
    background: #17181C 0% 0% no-repeat padding-box;
    display: inline-flex;
    width: 100%;
}

.scaffold-navbar-top {
    height: 84px;
    display: flex;
    padding-top: 20px;
    width: 100%;
}

.scaffold-navbar-top > div {
    margin-right: 20px;
}

.scaffold-search-icon {
    height: 17px;
    filter: invert(99%) sepia(0%) saturate(691%) hue-rotate(150deg) brightness(79%) contrast(101%);
    margin-left: 5px;
    margin-right: 10px;
}

.scaffold-search-input-icon {
    filter: invert(99%) sepia(0%) saturate(691%) hue-rotate(150deg) brightness(79%) contrast(101%);
    background: url('../../icons/icons8-search-30.png');
    background-repeat:no-repeat;
    background-position:center center;
    background-size: 17px 17px;
    height: 38px;
    width: 40px;
    position: absolute;
    display: inline-block;
}

.scaffold-navbar-top-search {
    flex: 1;
}

.scaffold-navbar-top-search-input {
    padding-left: 40px;
    border: none;
    background-color: transparent;
    color: #ECECEC;
    width: 100%;
}

input[type="search"] {
    -webkit-appearance: textfield;
}

.scaffold-navbar-top-search-list {
    width: 100%;
    list-style-type: none;
    padding-left: 0px;
    position: relative;
    background: rgb(32, 33, 36);
    box-shadow: 0px 3px 6px #000000;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    overflow: hidden;
}

.scaffold-navbar-top-search-listitem {
    width: 100%;
    color: #ECECEC;
    background: rgb(32, 33, 36);
    padding: 10px;
}

.scaffold-navbar-top-search-listitem:hover {
    background: rgb(52, 53, 56);
    cursor: default;
}

.scaffold-navbar-top-search-input::placeholder {
    color: #ECECEC;
}

.scaffold-navbar-top-vertical-bar {
    background: #EBEBF2;
    width: 1px;
    height: 33px;
}

.scaffold-navbar-top-user-fullname {
    font-size: 15px;
    line-height: 15px;
    color: #EBEBF2;
    padding: 10px;
    height: 15px;
    position: relative;
    outline: none;
    transition: 0.3s;
    border-radius: 5px;
}

.scaffold-navbar-top-user-fullname:hover {
    cursor: default;
    background: #282828;
}

.scaffold-main {
    margin-top: 84px;
    margin-left: 84px;
    display: flex;
}

.scaffold-navbar-left {
    height: 100%;
    position: fixed;
    z-index: 9999;
    top: 84px;
    width: 84px;
    background: #17181C;
}

.scaffold-navbar-item {
    border-left: transparent;
    border-left-width: 6px;
    border-left-style: solid;
}

.scaffold-navbar-item-highlight {
    border-left: #FFB70C;
    border-left-width: 6px;
    border-left-style: solid;
}

.scaffold-navbar-icon {
    padding-top: 35px;
    padding-left: 24px;
    padding-right: 24px;
    padding-bottom: 35px;
    width: 19px;
    height: 19px;
    filter: invert(95%) sepia(54%) saturate(5561%) hue-rotate(120deg) brightness(103%) contrast(102%);
}

.scaffold-navbar-icon-highlight,.scaffold-navbar-icon:hover {
    filter: invert(73%) sepia(95%) saturate(1687%) hue-rotate(350deg) brightness(103%) contrast(102%);
}

.scaffold-content {
    width: 100%;
    background: #000000 0% 0% no-repeat padding-box;
    padding: 40px;
}

.scaffold-header {
    text-align: left;
    font-size: 36px;
    letter-spacing: 0;
    color: #ECECEC;
    margin-bottom: 40px;
}

.scaffold-select-container {
    margin-bottom: 20px;
}

.scaffold-select {
    position: relative;
}

.scaffold-select > select {
    background: #282A2E 0% 0% no-repeat padding-box;
    color: #ECECEC;
    font-size: 20px;
    border: none;
    appearance: none;
    -moz-appearance:none; /* Firefox */
    -webkit-appearance:none; /* Safari and Chrome */
    padding: 15px;
    padding-right: 40px;
}

.scaffold-select-disabled > select {
    color: rgb(128,128,128)
}

.scaffold-select-disabled::after {
    color: rgb(128,128,128) !important
}

.scaffold-select::after {
    pointer-events: none;
    content: ">";
    font-size: 27px;
    transform: rotate(90deg);
    right: 10px;
    top: 0px;
    line-height: 18px;
    position: absolute;
    color: #ECECEC;
}

.scaffold-card-link:hover {
    background: rgb(52, 53, 56);
}

.scaffold-card-container {
    display: flex;
    flex-wrap: wrap;
}

.scaffold-card-stats {
    display: flex;
    flex-wrap: wrap;
    flex: 1;
}

.scaffold-card {
    width: 320px;
    box-shadow: 0px 3px 6px #000000;
    border-radius: 10px;
    color: #ECECEC;
    padding: 20px;
    margin-right: 20px;
    margin-bottom: 20px;
    display: flex;
    flex-direction: column;
}

.scaffold-card-bg-1 {
    background: rgb(48,45,35);
}

.scaffold-card-bg-2 {
    background: rgb(32,43,51);
}

.scaffold-card-bg-3 {
    background: rgb(32,33,36);
}

.scaffold-card-bg-4 {
    background: rgb(44,45,48);
}

.scaffold-card-menu-container {
    position: relative;
}

.scaffold-card-menu {
    background-repeat: no-repeat;
    background-image: url('../../icons/icons8-menu-vertical-50.png');
    background-size: 60%;
    background-position: center center;
    background-color: transparent;
    border: none;
    padding: 0px;
    margin: 0px;
    width: 30px;
    height: 30px;
    display: inline-block;
    position: absolute;
    right: 25px;
    top: 5px;
    outline: 0px;
    transition: 0.3s;
}

.scaffold-card-menu:focus {
    border-radius: 100%;
    border-width: 10px;
    background-color: rgb(70, 70, 70) !important;
}

.scaffold-card-menu:hover {
    border-radius: 100%;
    border-width: 10px;
    background-color: rgb(55, 55, 55);
}

.scaffold-card-menu:focus-within .scaffold-card-menu-item-container {
    display:inline-block;
}

.scaffold-card-menu-item-container {
    display: none;
    position: absolute;
    top: 30px;
    right: 0px;
    z-index: 9999;
    border-radius: 5px;
    box-shadow: 0px 3px 6px #000000;
}

.scaffold-navbar-top-user-fullname:focus {
    background-color: rgb(55, 55, 55) !important;
}

.scaffold-navbar-top-user-fullname:focus-within .scaffold-navbar-top-user-menu-item-container {
    display:inline-block;
    background-color: rgb(55, 55, 55);
}

.scaffold-navbar-top-user-menu-item-container {
    display: none;
    position: absolute;
    top: 40px;
    right: 0px;
    z-index: 9999;
    border-radius: 5px;
    box-shadow: 0px 3px 6px #000000;
}

.scaffold-card-title {
    font-size: 25px;
    flex: 2;
}

.scaffold-card-subtitle {
    font-size: 18px;
    flex: 3;
}

.scaffold-card-body {
    font-size: 40px;
    flex: 3;
}

.scaffold-card-footer {
    font-size: 20px;
}

.scaffold-card-recent-activity {
    width: 560px;
    flex: 3;
}

.scaffold-card-body-recent-activity {
    height: 400px;
    overflow-y: auto;
    font-size: 22px;
}

.scaffold-card-body-recent-activity .row {
    display: flex;
    padding-bottom: 5px;
}

.scaffold-card-body-recent-activity .col {
    flex: 1;
}

.scaffold-card-recent-activity-status {
    border-radius: 50px;
    color: #121212;
    padding-left: 8px;
    flex: 2;
    padding-top: 4px;
    padding-bottom: 0px;
    font-size: 14px;
}
.scaffold-card-recent-activity-status > a {
    color: #121212;
    font-size: 14px;
}
.scaffold-card-recent-activity-status > a:hover {
    text-decoration: underline;
}

.scaffold-card-recent-activity-status.bg-color-1,.scaffold-card-recent-activity-legend-ind.bg-color-1 {
    background: #FFB70C;
}

.scaffold-card-recent-activity-status.bg-color-2,.scaffold-card-recent-activity-legend-ind.bg-color-2 {
    background: #00A6ED;
}

.scaffold-card-recent-activity-status.bg-color-3,.scaffold-card-recent-activity-legend-ind.bg-color-3 {
    background: #16FFF7;
}

.scaffold-card-recent-activity-status.bg-color-4,.scaffold-card-recent-activity-legend-ind.bg-color-4 {
    background: #BBBBBB;
}

.scaffold-card-recent-activity-legend .row {
    display: flex;
    padding: 5px;
}

.scaffold-card-recent-activity-legend .col {
    flex: 1;
}

.scaffold-card-recent-activity-legend-ind {
    border-radius: 50px;
    height: 20px;
    width: 20px;
    float: left;
    margin-right: 10px;
}

.scaffold-card-body-list, .scaffold-card-body-list a {
    font-size: 14px;
    line-height: 28px;
    flex: 3;
}

.scaffold-most-used-operations-100 {
    width: 100%;
    height: 3px;
    background: #FFB70C;
    opacity: 1;
}

.scaffold-most-used-operations-70 {
    width: 70%;
    height: 3px;
    background: #FFB70C;
    opacity: 0.5;
}

.scaffold-most-used-operations-60 {
    width: 60%;
    height: 3px;
    background: #FFB70C;
    opacity: 0.5;
}

.scaffold-most-used-operations-50 {
    width: 50%;
    height: 3px;
    background: #FFB70C;
    opacity: 0.5;
}

.scaffold-card-heatmap {
    width: auto;
}

.scaffold-card-detail {
    margin: 5px;
}

.scaffold-card-detail-label {
    font-weight: bold;
    margin-top: 5px;
    margin-bottom: 5px;
}
