
.scaffold-card-accessgroup-detail {
    display: flex;
    flex-wrap: wrap;
    flex: 1
}

.fab.fab-ag-add-equipment,
.fab.fab-ag-add-operator {
    visibility: hidden;
    right: 25px;
    bottom: 0px;
    width: 48px;
    height: 48px;
    background-size: 48px;
    filter: invert(95%) sepia(54%) saturate(5561%) hue-rotate(120deg) brightness(103%) contrast(102%);
    background-color: transparent;
    box-shadow: none;
    transition: 0.3s;
    z-index: 0;
}

.fab.fab-ag-add-equipment {
    background-image: url('../../icons/icons8-plug-24.png');
}

.fab.fab-ag-add-operator {
    background-image: url('../../icons/icons8-worker-24.png');
}

.fab.multi:checked+label {
    transform: rotate(45deg)
}

.fab.multi:checked ~ .fab-ag-add-equipment {
    visibility: visible;
    bottom: 180px;
}

.fab.multi:checked ~ .fab-ag-add-operator {
    visibility: visible;
    bottom: 110px;
}

.fab.fab-ag-add-operator:active,
.fab.fab-ag-add-equipment:active {
    filter: invert(73%) sepia(95%) saturate(1687%) hue-rotate(350deg) brightness(103%) contrast(102%); 
    background-color: transparent !important;
}

.fab.fab-ag-add-operator:hover,
.fab.fab-ag-add-equipment:hover {
    filter: invert(73%) sepia(95%) saturate(1687%) hue-rotate(350deg) brightness(103%) contrast(102%);
    background-color: transparent;
}

.ag-btn-small {
    width: 50px;
    font-size: 10px;
    padding: 0;
    line-height: 10px;
    height: 30px;
    margin: 0px;
    margin-left: 10px;
}

.ag-list-item {
    margin-top: 5px;
    margin-bottom: 5px;
}

.ag-list-item a {
    max-width: 260px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: inline-block;
    white-space: nowrap;
}

.ag-list-item .ag-btn-small {
    float: right;
}